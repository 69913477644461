import React, { useEffect, useState, useContext } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { Alert } from "react-vm-component-library";
import "./App.scss";
import {
  getEventId,
  getSessionId,
  getRoomId,
  getUserId,
  getToken,
  getAccessToken,
  getIsApp,
  getReturnUrl
} from "./utils/QueryString";
import routes from "./constants/routes.json";
import Login from "./Components/login/Login";
import SessionInteractionPage from "./Components/tabs/SessionInteractionPage";
import QnAContext from "./context/QnAContext";
import { getCurrentQs, sentMatomo, transferTrackedSessions} from "./utils/Helper";
import { store } from "./utils/LocalStorage";
import { useHttp } from "./hooks/use-https";
import { deflate64} from "./utils/PakoHelper";
import {  getVmBaseUrl } from "./api/apiHelper";
import { isNullOrWhitespace } from "./utils/Validator";
import { makeHttpCall } from "./utils/HttpHelpers";
import AppContent from "./Components/common/AppContent";
import HomePage from "./Components/home/HomePage";
import QrcodeScanner from "./Components/qrcodeScanner/QrcodeScanner";
import MainMenu from "./Components/mainMenu/MainMenu";
import UserTracking from "./Components/userTracking/UserTracking";
import RedirectTo from "./Components/redirectTo/RedirectTo";

function App() {
  // const [eventId, setEventId] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [encryptMode, setEncryptMode] = useState(false);
  const [isLoadingLogin, setIsLoadingLogin] = useState(false);
  const [loginData,setLoginData] = useState(null);
  // const [loginDataSet, setLoginDataSet] = useState(true);

  const {
    QnaConfig,
    setQnaConfig,
    specificSettings,
    timeZoneStr,
    eventId,
    roomsConfig,
    streamConfig,
    qrcodeScannerModule,
    mainMenuModule,
    userTrackingModule,
    setTrackedCount,
    trackedCount,
    text,
    qnaAppSettings,
    loginBroadcasterConfig
  } = useContext(QnAContext);

  const navigate = useNavigate();
  const httpHook = useHttp();

  const onRoomHandler = (roomObject) => {
    const sid = roomObject.session_id;
    const deflatedSid = deflate64(String(sid));

    sentMatomo(
      `${getEventId()}`,
      "Room view click",
      `${getEventId()}_${roomObject.room_id}`
    );

    if (encryptMode) {
      navigate(
        {
          pathname: `${routes.SESSIONS}/${deflatedSid}`,
          search: getCurrentQs(),
        }
        //{ replace: true }
      );
    } else {
      navigate(
        {
          pathname: `${routes.SESSIONS}/${sid}`,
          search: getCurrentQs(),
        }
        //{ replace: true }
      );
    }

    store("last.sessionId", roomObject.session_id);
  };

  const roomFetching = (qsRid, qsToken) => {
    const url = `https://${getVmBaseUrl()}/v1/contentsessions.json?room_id=${qsRid}&output=mtv&is_sponsor=-1`;
    httpHook.fetchData({ url: url }, (pData) => {
      // setEventId(pData[0].event_id);
      sentMatomo(
        `${getEventId()}`,
        "Direct Room View",
        `${getEventId()}_${qsRid}`
      );
      store("last.sessionId", pData[0].id);
      
      navigate(
        {
          pathname:
            qsToken === null
              ? `${routes.SESSIONS}/${pData[0].id}`
              : `${routes.SESSIONS}/${deflate64(String(pData[0].id))}`,
          search: getCurrentQs(),
        },
        { replace: true }
      );
    });
  };

  const logOut = (sid) => {
    setIsLoadingLogin(true);
    localStorage.removeItem(`${getEventId()}.qna.user`);
    if(sid)
      sentMatomo(`${getEventId()}`, "User Logout Session", `${sid}`);
    else
      sentMatomo(`${getEventId()}`, "User Logout Session", `${sid}`);  

    if (getAccessToken() && getUserId()) {
      const tokenParams = getIsApp() ? {isApp:"true", returnUrl:getReturnUrl()}:{}
      const restParams = getIsApp() ? `&isApp=true&returnUrl=${getReturnUrl()}`:""
      navigate({
        pathname: window.location.pathname,
        search: getToken()
          ? `token=${deflate64({ event_id: getEventId(), ...tokenParams })}`
          : `event_id=${getEventId()}${restParams}`,
      });
    }
    setQnaConfig((prevState) => ({
      ...prevState,
      isChairTable: specificSettings.qnaForceLogin ? true : false,
      commentLikes: false,
      userObject: {
        name: "",
        fullName: "",
        access_token: "",
        user_id: "",
      },
    }));
    setIsLoggedIn(false);
    window.location.reload();
  };


  useEffect(() => {
    const lEventId = getEventId();
    const qsSid = getSessionId();
    const qsRid = getRoomId();
    const qsToken = getToken();
    const qsIsApp = getIsApp();
    const qsReturnUrl = getReturnUrl();
    
    sentMatomo(`${lEventId}`,"Page load","");

    if (qsToken !== null) setEncryptMode(true);

    // if (qsUserId !== null) store("qna.user", JSON.parse(inflate64(qsToken)));

    if (qsSid) {
      store("last.sessionId", Number(qsSid));
      // window._paq.push([
      //   "trackEvent",
      //   `${getEventId()}`,
      //   "Direct Session View",
      //   `${qsSid}`,
      // ]);
      navigate(
        {
          pathname:
            qsToken === null
              ? `${routes.SESSIONS}/${qsSid}`
              : `${routes.SESSIONS}/${deflate64(qsSid)}`,
          search: getCurrentQs(),
        },
        { replace: true }
      );
    } else if (qsRid !== null) {
      roomFetching(qsRid, qsToken);
    } else if (!lEventId) {
      setEncryptMode(true);
      let qsPart = `?token=${deflate64(
        JSON.stringify({ event_id: `${eventId}` })
      )}${qsIsApp ? `&isApp=${qsIsApp}&returnUrl=${qsReturnUrl}` : ""}`;
      if (
        !isNullOrWhitespace(window.location.pathname) &&
        window.location.pathname[window.location.pathname.length - 1] !== "/"
      ) {
        qsPart = `?token=${deflate64(
          JSON.stringify({ event_id: `${eventId}` })
        )}${qsIsApp ? `&isApp=${qsIsApp}&returnUrl=${qsReturnUrl}` : ""}`;
      }
      
      if(qnaAppSettings?.redirectConfig?.active){
        navigate({
          pathname: qnaAppSettings?.redirectConfig?.to,
          search:qsPart.substring(1)
        });
        return;
      }

      navigate({
        //pathname: `/?event_id=${pData[0].event_id}`,
        //pathname: `${window.location.pathname}${qsPart}`,
        pathname: `${qsPart}`,
      });
    }
  }, []);

  const getUserInfo = async(url) => {
    setIsLoadingLogin(true);
    const userInfo = await makeHttpCall({
      url: url,
      method: "GET",
      isVm: true,
    });

    if (userInfo?.ok) {
      const responseUSerData = userInfo?.data[0]?.user
      let date = new Date();
      const tokenObj = {
        event_id: getEventId(),
        session_id: getSessionId(),
        user_id: getUserId(),
        access_token: getAccessToken(),
        user: {
          firstname: responseUSerData?.firstname || " ",
          surname: responseUSerData?.surname || " ",
        },
        validUntil: date.setDate(date.getDate() + 14),
      };
      localStorage.setItem(`${eventId}.qna.user`, JSON.stringify(tokenObj));
      setQnaConfig((prevState) => ({
        ...prevState,
        userObject: {
          ...prevState.userObject,
          name: responseUSerData?.firstname || " ",
          fullName: (responseUSerData?.firstname ||"") + (responseUSerData?.firstname ? " ":"") + (responseUSerData?.surname || ""),
          access_token: getAccessToken(),
          user_id: getUserId(),
        },
      }));
      setIsLoggedIn(true)
      setIsLoadingLogin(false);
    }

    if(!userInfo?.ok){

      localStorage.removeItem(`${eventId}.qna.user`)
      setQnaConfig((prevState) => ({
        ...prevState,
        userObject: {
          ...prevState.userObject,
          name: "",
          fullName: "",
          access_token: "",
          user_id: "",
        },
      }));
      setIsLoggedIn(false);
      setIsLoadingLogin(false);
    }
  }

  const doJwtLogin = async(tokenObject) => {
    setIsLoadingLogin(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        event_id: getEventId(),
        jwt_token: tokenObject.accessToken,
        include_user: 1,
        include_grants: 0,
      }),
    };
    await fetch(`https://${getVmBaseUrl()}/v0/logins/jwt.json`, requestOptions)
      .then((response) => {
        if (response?.ok) {
          return response.json();
        }
        throw new Error("Not valid token");
      })
      .then((data) => {
        // setVmUser(data);
        const tokenObj = {
          event_id: getEventId(),
          session_id: getSessionId(),
          user_id: data?.user_id,
          access_token: data?.access_token,
          user: {
            firstname: data?.user?.firstname || " ",
            surname: data?.user?.surname || " ",
          },
          validUntil: new Date(data.expires_at).valueOf(),
        };
        localStorage.setItem(`${eventId}.qna.user`, JSON.stringify(tokenObj));
        setQnaConfig((prevState) => ({
          ...prevState,
          userObject: {
            ...prevState.userObject,
            name: data?.user?.firstname || " ",
            fullName: (data?.user?.firstname || "") + (data?.user?.firstname ? " " : "") + (data?.user?.surname || ""),
            access_token: data?.access_token,
            user_id: data?.user_id,
          },
        }));
        setIsLoggedIn(true);
        setIsLoadingLogin(true);
      })
      .catch((error) => {
        console.log("login error", error);
        localStorage.removeItem(`${eventId}.qna.user`);
        setQnaConfig((prevState) => ({
          ...prevState,
          userObject: {
            ...prevState.userObject,
            name: "",
            fullName: "",
            access_token: "",
            user_id: "",
          },
        }));
        setIsLoggedIn(false);
        setIsLoadingLogin(false);
      });
  }

  useEffect(() => {
    //Change the qna.user to eventId.qna.user (the reason not to put eventId in last position is for jwt login component)
    //      tokenobj format = {
    //         event_id: getEventId(),
    //         session_id: getSessionId(),
    //         user_id: getUserId(),
    //         access_token: getAccessToken(),
    //         user: { firstname: "", surname: "" },
    //         validUntil: date.setDate(date.getDate() + 14),
    //       };
    const tokenObject = localStorage.getItem(`${eventId}.qna.user`);
    const parsedTokenObject = (tokenObject && tokenObject !== null) ? JSON.parse(tokenObject) : null
    const qsAccessToken = getAccessToken();
    const qsUserId = getUserId();
    const qsToken = getToken();

    //First check if the user is using vmx or ean if yes direct login ignore the translation settings
    if(qsUserId && qsAccessToken){
      // const url = `https://${getVmBaseUrl()}/v1/users/${getUserId()}.json`;
      const url = `https://${getVmBaseUrl()}/v1/logins.json?user_id=${qsUserId}&access_token=${qsAccessToken}&include_user=1`;
      getUserInfo(url)
    }else{
      //Now comes the regular login with normal translation config
      // if(specificSettings.showLogin){
      // First check if the user login before and if the token is still valid
      // Check if token object is qna format
        if(tokenObject && tokenObject !== null && parsedTokenObject?.user && parsedTokenObject?.event_id){
          setIsLoadingLogin(true);
          const currentTimestamp = new Date().valueOf();
          const loginData = JSON.parse(tokenObject);

          //If there's token object (login info) in localstorage check if the token is still valid or expired
          if(loginData?.validUntil && Number(loginData?.validUntil) > currentTimestamp){
            //If loginData in localstorage is still valid directly set isLoggedIn
            setQnaConfig((prevState) => ({
              ...prevState,
              userObject: {
                ...prevState.userObject,
                name: loginData?.user?.firstname || " ",
                fullName: (loginData?.user?.firstname || "") + (loginData?.user?.firstname ? " ":"") + (loginData?.user?.surname || ""),
                access_token: loginData?.access_token,
                user_id: loginData?.user_id,
              },
            }));
            setIsLoggedIn(true);
            setIsLoadingLogin(false);
          }else{
            // the loginData is not valid any longger set is logged in to false and remove the loginData in localstorage
            localStorage.removeItem(`${eventId}.qna.user`)
            setQnaConfig((prevState) => ({
              ...prevState,
              userObject: {
                ...prevState.userObject,
                name: "",
                fullName: "",
                access_token: "",
                user_id: "",
              },
            }));
            setIsLoggedIn(false);
            setIsLoadingLogin(false);
          }
        }

        //If using the jwt login the tokenObject format will be different so need to get new login data from vm API
        //And then format the data
        if(tokenObject && (!parsedTokenObject?.user || !parsedTokenObject?.event_id)){
          doJwtLogin(parsedTokenObject);
        }

        // If get nothing then set login to false
        if(!tokenObject || tokenObject === null){
          setIsLoadingLogin(false);
          setIsLoggedIn(false)
        // }
      }
    }

    const lUserObject = isLoggedIn
      ? JSON.parse(localStorage.getItem(`${eventId}.qna.user`))
      : null;
    setLoginData(lUserObject);

    if (isLoggedIn) {
      //note: send untracked sessions
      transferTrackedSessions(lUserObject?.user_id, setTrackedCount);
    }

  }, [isLoggedIn]);

  if (eventId === undefined || eventId === null || isLoadingLogin) {
    return (
      <AppContent loading>
        <div className="spinner-border"></div>
      </AppContent>
    );
  }

  if (isLoggedIn && specificSettings.qnaForceLogin) {
    if (
      QnaConfig.userObject.name === "" ||
      QnaConfig.userObject.access_token === null
    ) {
      return (
        <AppContent loading>
          <div className="spinner-border"></div>
        </AppContent>
      );
    }
  }
  let viewModeListCustomIcon = null;
  if (streamConfig?.active) {
    viewModeListCustomIcon = {
      fontAwesomeClass: "fas fa-headphones-alt",
      roomIds: streamConfig.roomsIds,
    };
  }
  const cssClasses =["qna-v2-main-container"];
  if(mainMenuModule?.active)cssClasses.push("has-main-menu");
  return (
    <div className={cssClasses.join(" ")}>
      {mainMenuModule?.active && (
        <MainMenu mainMenuModule={mainMenuModule} trackedCount={trackedCount} />
      )}
      <Routes>
        <Route
          exact
          path={routes.HOME}
          element={
            <HomePage
              specificSettings={specificSettings}
              eventId={eventId}
              viewModeListCustomIcon={viewModeListCustomIcon}
              timeZoneStr={timeZoneStr}
              onRoomHandler={onRoomHandler}
              roomsConfig={roomsConfig}
              qrcodeScannerModule={qrcodeScannerModule}
              setIsLoggedIn={setIsLoggedIn}
              isLoggedIn={isLoggedIn}
            />
          }
        />
        {mainMenuModule?.items?.scanQrCode?.active && (
          <Route
            exact
            path={routes.QRCODESCANNER}
            element={
              <QrcodeScanner
                qrcodeScannerModule={qrcodeScannerModule}
                isLoggedIn={isLoggedIn}
                setIsLoggedIn={setIsLoggedIn}
                logOut={logOut}
                loginData={loginData}
                setTrackedCount={setTrackedCount}
                loginBroadcasterConfig={loginBroadcasterConfig}
                eventId={eventId}
                text={text}
              />
            }
          />
        )}
        {mainMenuModule?.items?.myTracking?.active && (
          <Route
            exact
            path={routes.MYTRACKING}
            element={
              <UserTracking
                isLoggedIn={isLoggedIn}
                setIsLoggedIn={setIsLoggedIn}
                userTrackingModule={userTrackingModule}
                qrcodeScannerModule={qrcodeScannerModule}
                loginData={loginData}
                setTrackedCount={setTrackedCount}
                eventId={eventId}
              />
            }
          />
        )}
        <Route
          exact
          path={`${routes.SESSIONS}/:id`}
          element={
            <SessionInteractionPage
              isLoggedIn={isLoggedIn}
              setIsLoggedIn={setIsLoggedIn}
              encryptMode={encryptMode}
              setIsLoadingLogin={setIsLoadingLogin}
              logOut={logOut}
            />
          }
        />

        <Route
          path={`${routes.LOGIN}/`}
          element={<Login encryptMode={encryptMode} />}
        />
        <Route
          exact
          path={`${routes.SESSIONEXPIRED}/`}
          element={
            <AppContent loading>
              <Alert
                style={{
                  margin: "1rem",
                  padding: "1rem",
                  borderRadius: "5px",
                  display: "flex",
                }}
              >
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.2rem",
                    textAlign: "center",
                  }}
                >
                  The session expired. To access the next session please scan
                  the corresponding QR Code.
                </span>
              </Alert>
            </AppContent>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
