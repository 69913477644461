import React, { useEffect } from "react";
import { useVmWsSocket } from "react-vm-component-library";

const SocketContainer = ({ socketUrl, channelName, onSendToChannelRef }) => {
  const { subscribe, unsubscribe, sendToChannel, isConnectionOpen, channels } =
    useVmWsSocket(socketUrl);
  console.log(channels);
  useEffect(() => {
    if (!isConnectionOpen || !channelName) return;

    subscribe(channelName, (data) => {
      console.log(data);
    });
    const timeoutId = setTimeout(() => {
      //   sendToChannel(channelName, {
      //     ...(user || {}),
      //     timestamp: Date.now(),
      //   });
    }, 1 * 1000);

    if (onSendToChannelRef) {
      onSendToChannelRef.current = sendToChannel;
    }

    return () => {
      unsubscribe(channelName);
      clearTimeout(timeoutId); // Cleanup the timeout
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnectionOpen]);
  return <></>;
};

export default SocketContainer;
