import React from "react";
import moment from "moment-timezone";

const TrackedItem = ({
  contentsession,
  onEvaluationClick,
  isLogged,
  userTrackingModule,
}) => {
  const { txtBtnEvaluate, txtTransmitted, txtNotTransmitted } =
    userTrackingModule || {};
  return (
    <div className="qna-user-tracked-sessions__item">
      <div className="qna-user-tracked-sessions__left">
        <div className="datetime">
          {moment(contentsession?.starts_at_local).format("DD.MM.YYYY HH:mm")}
        </div>
        <div>{contentsession?.title}</div>
      </div>
      <div className="qna-user-tracked-sessions__right">
        <span className="label">
          {isLogged ? txtTransmitted : <span className="label--red">{txtNotTransmitted}</span>}
        </span>
        <div>
          <button
            className="btn btn-primary btn-sm "
            disabled={isLogged ? false : true}
            onClick={() => onEvaluationClick(contentsession)}
          >
            {txtBtnEvaluate}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TrackedItem;
