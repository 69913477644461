import { saveUserInStorage } from "../utils/Helper";
import { makeHttpCall } from "../utils/HttpHelpers";
import { getApiUrl, getEventId, getUserId } from "../utils/QueryString";

export const getVmBaseUrl = () => {
  const qsUrl = getApiUrl();
  if (qsUrl) return qsUrl;
  const currUrl = window.location.href.toLowerCase();
  // if(currUrl.includes("qna.at/eadv") || currUrl.includes("eadv.qna.at") ||currUrl.includes("/eadv")|| getEventId() === "12")
  //   return "eadv-api-lb.virtual-meeting.net";
  if(currUrl.includes("qna.at/easd") || currUrl.includes("easd.qna.at") || getEventId() === "20" )
    return "easd-api-lb.virtual-meeting.net";
    
  return "api-lb.virtual-meeting.net";
  //dev-api.virtual-meeting.net
};

export const fetchAppConfig = async ({translatable_id, translation_language="onsite.qna.config", translatable_type="Event", use_cache=1}) => {
  const url = `https://${getVmBaseUrl()}/v1/translationkey_mappings.json?include_translations=1&include_translatable=1&include_translationkey=1&translation_language=${translation_language}&translatable_type=${translatable_type}&translatable_id=${translatable_id}&use_cache=${use_cache}`;
  return await makeHttpCall({
    //url : `${url}?${Object.keys(params).map(key=>`${key}=${params[key]}`).join('&')}`,
    url,
    method: "GET",
    isVm: true,
  });
};

export const getUserInfo = async() => {
  const url = `https://${getVmBaseUrl()}/v1/users/${getUserId()}.json`;
  return await makeHttpCall({
    url,
    method:"GET",
    isVm:true,
  })
}

export const getSessionsByIds = async(eventId, pSessionIds) => {
  const url = `https://${getVmBaseUrl()}/v1/contentsessions/qna.json?event_id=${eventId}&ids=${pSessionIds.join(",")}`;
  return await makeHttpCall({
    url,
    method:"GET",
    isVm:true,
  })
}


export const createTrackings = async(userId, sessionId) => {

  const postData = new FormData();
  postData.append("user_id", userId);
  postData.append("session_id", `Qna_${getEventId()}`);
  postData.append("contentsession_id",sessionId);
  postData.append("mode","qna");
  
  const url = `https://${getVmBaseUrl()}/v1/playbacks.json`;
  return await makeHttpCall({
    url,
    method:"POST",
    isVm:true,
    bodyJson: postData,
  })
}

export const getTrackings = async(userObj) => {
  const params ={
    id:userObj?.user_id,
    access_token:userObj?.access_token,
    session_id:`Qna_${getEventId()}`,
    source:"contentsession",
    include_contentsession:1
  };
  
  const url = `https://${getVmBaseUrl()}/v1/users/${userObj?.user_id}/playbacks.json`;
  return await makeHttpCall({
    url : `${url}?${Object.keys(params).map(key=>`${key}=${params[key]}`).join('&')}`,
    method:"GET",
    isVm:true,
  })
}


export const vmLoginHelper = async(props) => {

  const {isQrCodeLogin, postData, saveInStorage} = props;

  const httpResp = await makeHttpCall({
    url: `https://${getVmBaseUrl()}/v1/logins.json`,
    method: isQrCodeLogin ? "PUT":"POST",
    isVm: true,
    bodyJson: postData,
  });

  if (httpResp?.ok) {
    if(saveInStorage) saveUserInStorage(httpResp?.data);
    return {ok: true, data:httpResp?.data};
  }

  if(!httpResp?.ok){
    return {ok: false, data:null};
  }

}

export const qrCodeLoginHelper = async (barcodeId) => {
  const postData = new FormData();
  postData.append("event_id", getEventId());
  postData.append("mode", "barcode_access");
  postData.append("include_grants", 0);
  postData.append("extra_check", barcodeId);

  const httpResp = await makeHttpCall({
    url: `https://${getVmBaseUrl()}/v1/logins.json`,
    method: "PUT",
    isVm: true,
    bodyJson: postData,
  });

  if (httpResp?.ok) {
    return {ok: true, ...httpResp?.data};
  }

  if(!httpResp?.ok){
    return {ok: false};
  }
};
