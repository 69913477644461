import React from "react";
import { isNullOrUndefined } from "../../utils/Validator";
import { checkTabs } from "../../utils/Helper";

const CSS_ACTIVE_TAB = "Tabs__nav--active";

const TabMenu = ({
  specificSettings,
  qnaNumberCount,
  session,
  isLoggedIn,
  loginModalOpen,
  votingCallback,
  setLoginModalOpen,
  isTabActive,
  setActiveTab,
  setVotingCallBack,
}) => {
  return (
    <ul className="Tabs__nav">
      <li
        className={isTabActive("qna") ? CSS_ACTIVE_TAB : ""}
        onClick={() => {
          !isLoggedIn && setLoginModalOpen(!loginModalOpen);
          setActiveTab("qna");
        }}
        style={
          checkTabs(
            "qna",
            specificSettings.availableTabs,
            session?.qna_allowed,
            specificSettings.sessionbasedDynamicTabs
          )
            ? null
            : { display: "none" }
        }
      >
        {specificSettings.qnaTabName}
        {!isNullOrUndefined(qnaNumberCount) && (
          <div className="jme-badge">
            {qnaNumberCount >= 100 ? "99+" : qnaNumberCount}
          </div>
        )}
      </li>
      <li
        className={isTabActive("polling") ? CSS_ACTIVE_TAB : ""}
        onClick={() => {
          setActiveTab("polling");
          setVotingCallBack(false);
        }}
        style={
          checkTabs(
            "polling",
            specificSettings.availableTabs,
            session?.voting_allowed,
            specificSettings.sessionbasedDynamicTabs
          )
            ? null
            : { display: "none" }
        }
        //fas fa-exclamation
      >
        {specificSettings.pollingTabName}
        {votingCallback && <i className="fas fa-poll"></i>}
      </li>
      <li
        className={`${isTabActive("evaluation") ? CSS_ACTIVE_TAB : ""}`}
        onClick={() => setActiveTab("evaluation")}
        style={
          checkTabs(
            "evaluation",
            specificSettings.availableTabs,
            session?.evaluation_allowed,
            specificSettings.sessionbasedDynamicTabs
          )
            ? null
            : { display: "none" }
        }
      >
        {specificSettings.evaluationTabName}
        {session?.evaluation_allowed && (
          <i
            className="fas fa-exclamation"
            style={{ color: "red", animation: "none", fontSize: "14px" }}
          ></i>
        )}
      </li>
    </ul>
  );
};

export default TabMenu;
