import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import TabMsg from "../tabs/TabMsg";
import { Evaluation } from "react-vm-component-library";
import { getVmBaseUrl } from "../../api/apiHelper";
import { replaceBracketNtoMinute } from "../../utils/Helper";

const EvaluationContent = ({
  session,
  evaluationConfig,
  userObject,
  timeZoneStr,
  checkShowBeforeLogin,
  isTabActiveCheck,
  showMustBeLoggedIn,
}) => {
  const [timerShowEvaluation, setTimeShowEvaluation] = useState(false);

  useEffect(() => {
    if (session?.show_evaluation_before_ends_at_seconds || session?.show_evaluation_after_starts_at_seconds) {
      const start = moment(session?.starts_at_local).tz(timeZoneStr);
      const end = moment(session?.ends_at_local).tz(timeZoneStr);
      // const end = moment(new Date("5/29/2023 13:26")).tz(timeZoneStr)
      if(session?.show_evaluation_after_starts_at_seconds){
        const interval = setInterval(() => {
          const now = moment(new Date().getTime()).tz(timeZoneStr);
  
          const timeDiff = now.diff(moment(start), "seconds");
          // const timeDiff = 900;
          console.log("setting interval not yet", timeDiff);
          if (timeDiff >= session?.show_evaluation_after_starts_at_seconds) {
            console.log("setting interval showing", timeDiff);
            setTimeShowEvaluation(true);
            clearInterval(interval);
          }
        }, 1000);
        return () => clearInterval(interval);
      }else{
        const interval = setInterval(() => {
          const now = moment(new Date().getTime()).tz(timeZoneStr);
  
          const timeDiff = end.diff(moment(now), "seconds");
          console.log("setting interval not yet", timeDiff);
          if (timeDiff <= session.show_evaluation_before_ends_at_seconds) {
            console.log("setting interval showing", timeDiff);
            setTimeShowEvaluation(true);
            clearInterval(interval);
          }
        }, 1000);
        return () => clearInterval(interval);
      }


      
    }
  }, [session]);
  // Mon May 29 2023 12:16:50 GMT+0200

  const checkBeforeAfterSession = () => {
    if(session?.show_evaluation_after_starts_at_seconds){
      return replaceBracketNtoMinute(
        evaluationConfig?.evaluationAfterTimerText,
        session.show_evaluation_after_starts_at_seconds / 60
      )
    }

    if(session?.show_evaluation_before_ends_at_seconds){
      return replaceBracketNtoMinute(
        evaluationConfig?.evaluationBeforeTimerText,
        session.show_evaluation_before_ends_at_seconds / 60
      )
    }
  }

  if (showMustBeLoggedIn)
    return (
      <TabMsg type="flex" hide={!isTabActiveCheck("evaluation")}>
        {evaluationConfig.showAfterLoginText}
      </TabMsg>
    );
  let evaluationLink = session?.special_settings?.vm_talk?.mtv_evaluation_link;
  if (session?.evaluation_link) evaluationLink = session?.evaluation_link;
  if (
    evaluationLink?.includes(evaluationConfig.userIdTmpl) &&
    userObject?.user_id
  )
    evaluationLink = evaluationLink.replace(
      evaluationConfig.userIdTmpl,
      userObject?.user_id
    );

  let currContent;
  if (!evaluationLink)
    currContent = (
      <>
        {session?.evaluation_type === "component" ? (
          <>
            {(session?.show_evaluation_before_ends_at_seconds || session?.show_evaluation_after_starts_at_seconds) ? (
              <>
                {timerShowEvaluation ? (
                  <Evaluation
                    base_api={getVmBaseUrl()}
                    contentsessionId={session.id}
                    eventId={session.event.id}
                    isAnonymous={!checkShowBeforeLogin("evaluation")}
                    useTranslationConfig={
                      evaluationConfig?.useTranslationConfig
                    }
                    userId={userObject.user_id}
                    access_token={userObject.access_token}
                    onSubmitShowModal={false}
                    evaluationText={{...evaluationConfig?.evaluationText}}
                  />
                ) : (
                  <TabMsg>
                    {checkBeforeAfterSession()}
                  </TabMsg>
                )}
              </>
            ) : (
              <Evaluation
                base_api={getVmBaseUrl()}
                contentsessionId={session.id}
                eventId={session.event.id}
                isAnonymous={!checkShowBeforeLogin("evaluation")}
                useTranslationConfig={evaluationConfig?.useTranslationConfig}
                userId={userObject.user_id}
                access_token={userObject.access_token}
                onSubmitShowModal={false}
                evaluationText={{...evaluationConfig?.evaluationText}}
              />
            )}
          </>
        ) : (
          <TabMsg>{evaluationConfig.noValueText}</TabMsg>
        )}
      </>
    );
  else
    currContent = (
      <>
        {(session?.show_evaluation_before_ends_at_seconds ||
        session?.show_evaluation_after_starts_at_seconds) ? (
          <>
            {timerShowEvaluation ? (
              <a
                className="btn Tabs__outlet__evaluation__button"
                href={evaluationLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {evaluationConfig.buttonText}
              </a>
            ) : (
              <TabMsg>{checkBeforeAfterSession()}</TabMsg>
            )}
          </>
        ) : (
          <a
            className="btn Tabs__outlet__evaluation__button"
            href={evaluationLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {evaluationConfig.buttonText}
          </a>
        )}
      </>
    );
  if (!session?.evaluation_allowed)
    currContent = <TabMsg>{evaluationConfig?.noEvaluationForSession}</TabMsg>;
  return (
    <div
      className="Tabs__outlet__evaluation"
      style={isTabActiveCheck("evaluation") ? {} : { display: "none" }}
    >
      {currContent}
    </div>
  );
};

export default EvaluationContent;
