import React from "react";
import { getIsApp, getIsDemo, getReturnUrl } from "../../utils/QueryString";
import { getVmBaseUrl } from "../../api/apiHelper";
import { RoomsOverview } from "react-vm-component-library";
import "./HomePage.scss"

const HomePage = ({
  specificSettings,
  eventId,
  viewModeListCustomIcon,
  timeZoneStr,
  roomsConfig,
  onRoomHandler
}) => {

  return (
    <div className="container">
      {(specificSettings?.roomsOverviewBackBtn?.active ||
        (getIsApp() && getIsApp() === "true")) && (
        <button
          className="btn btn-default"
          onClick={() => {
            if (getReturnUrl()) return (window.location.href = getReturnUrl());

            return (window.location.href =
              specificSettings?.roomsOverviewBackBtn?.href);
          }}
        >
          <i className="fas fa-angle-left mr-1"></i>
          <span>
            {specificSettings?.roomsOverviewBackBtn?.text || "Back to"}
          </span>
        </button>
      )}
        <RoomsOverview
          event_id={eventId}
          base_api={getVmBaseUrl()}
          onRoomClick={onRoomHandler}
          timeZoneStr={timeZoneStr}
          isDemo={getIsDemo() ? true : false}
          // api_starts_at="2023-05-29"
          //api_ends_at="2022-09-31"
          liveIconType="type3"
          viewMode="list"
          viewModeListCustomIcon={viewModeListCustomIcon}
          {...roomsConfig}
          /*api_has_streaming={parseInt(
                  specificSettings.roomOverviewApiStreaming
                )}*/
        />
    </div>
  );
};

export default HomePage;
