import React, { useState } from "react";
import { getEventId } from "../../utils/QueryString";
import { vmLoginHelper } from "../../api/apiHelper";
import { isMailValid } from "../../utils/Validator";
import Small from "./Small";
import { isEmpty } from "lodash";
import { Modal } from "react-vm-component-library";

function VmLogin({ setIsLoggedIn, userTrackingModule, qrcodeScannerModule }) {
  const [userCred, setUserCred] = useState({ email: "", password: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [validation, setValidation] = useState(null);
  const [showModal, setShowModal] = useState(null);

  const loginAction = async (e) => {
    e.preventDefault();
    let isValid = isMailValid(userCred?.email);
    let validationObj = {};
    if (!isValid) {
      validationObj["email"] = {
        text: userTrackingModule?.txtValEmail,
      };
    }
    isValid = userCred?.password?.length >= 3;
    if (!isValid) {
      validationObj["password"] = {
        text: userTrackingModule?.txtValPassword,
      };
    }
    if (!isEmpty(validationObj)) {
      setValidation(validationObj);
      return;
    }
    setValidation(null);

    setIsLoading(true);
    const postData = new FormData();
    postData.append("event_id", getEventId());
    postData.append("email", userCred.email);
    postData.append("password", userCred.password);
    postData.append("include_user", 1);
    const userLogin = await vmLoginHelper({
      postData: postData,
      saveInStorage: true,
    });
    setIsLoading(false);
    if (userLogin.ok) {
      //setIsLoggedIn(true);
      setShowModal(true);
    } else {
      setValidation({
        summary: {
          text: userTrackingModule?.txtValGeneral,
        },
      });
    }
  };

  return (
    <form onSubmit={(e) => loginAction(e)}>
      {showModal && (
        <Modal
          className="modal-login-sucess"
          onClose={()=>setIsLoggedIn(true)}
          onYesClick={()=>setIsLoggedIn(true)}
          rootId="modal-root"
          title={userTrackingModule?.txtModalTitleLoginSucess}
          txtBtnYes={qrcodeScannerModule?.txtModalOk}
          txtBtnNo={qrcodeScannerModule?.txtModalCancel}
          dismissible={false}
        >
          {userTrackingModule?.txtModalMsgLoginSucess}
        </Modal>
      )}
      <div className="mb-2">{userTrackingModule?.siginInInfoTxt}</div>
      <div className="form-group">
        <label htmlFor="exampleInputEmail1">
          {userTrackingModule?.txtEmailLabel}
        </label>
        <input
          type="email"
          onChange={(e) =>
            setUserCred((prev) => ({ ...prev, email: e.target.value }))
          }
          className="form-control"
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
          placeholder={userTrackingModule?.txtEmailPlaceholder}
          value={userCred.email}
        />
        {validation?.email && <Small>{validation?.email?.text}</Small>}
      </div>
      <div className="form-group">
        <label htmlFor="exampleInputPassword1">
          {userTrackingModule?.txtPasswordLabel}
        </label>
        <input
          type="password"
          onChange={(e) =>
            setUserCred((prev) => ({ ...prev, password: e.target.value }))
          }
          className="form-control"
          id="exampleInputPassword1"
          placeholder={userTrackingModule?.txtPasswordPlaceholder}
          value={userCred.password}
        />
        {validation?.password && <Small>{validation?.password?.text}</Small>}
      </div>
      <button
        type="submit"
        className={`qna-user-tracking__scan-container__button ${
          isLoading ? "jme-qna-disabled" : ""
        }`}
      >
        {isLoading && <i className="fas fa-spinner fa-spin mr-1"></i>}
        {userTrackingModule?.submitBtnTxt}
      </button>
      {validation?.summary && (
        <div>
          <Small>{validation?.summary?.text}</Small>
        </div>
      )}
    </form>
  );
}

export default VmLogin;
