import { useNavigate } from "react-router";
import routes from "../../constants/routes.json"
import VmLogin from "../login/VmLogin";
import { getCurrentQs } from "../../utils/Helper";

const LoginView = ({userTrackingModule, setIsLoggedIn, ...rest}) => {
  const navigate = useNavigate();
  return (
    <div className="container">
      <div className="qna-user-tracking__scan-container">
        <span>{userTrackingModule?.tipTxt}</span>
        <button
          className="qna-user-tracking__scan-container__button"
          onClick={() =>
            navigate({
              pathname: routes.QRCODESCANNER,
              search: getCurrentQs(),
            })
          }
        >
          {userTrackingModule?.scanBtnTxt}
        </button>
      </div>
      <div style={{ marginTop: "1.5rem" }}>
        <VmLogin
          setIsLoggedIn={setIsLoggedIn}
          userTrackingModule={userTrackingModule}
          {...rest}
        />
      </div>
    </div>
  );
};

export default LoginView;
