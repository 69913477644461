import React from "react";
import {
  getIsApp,
} from "../../utils/QueryString";


const Header = ({
  specificSettings,
  sid,
  text,
  isLoggedIn,
  onBackToRooms,
  onLogin,
  navigate,
  logOut
}) => {
  
  const logoutBtnJsx = (
    <button
      className="btn btn-default back-btn-container__loginOutButton"
      onClick={() => logOut(sid)}
    >
      <i className="fas fa-sign-out-alt mr-1"></i>
      <span>{text?.txtBtnLogout}</span>
    </button>
  );
  return (
    <div className="qna-v2-main-container__header">
      <div className="back-btn-container">
        {specificSettings.showRoomOverview && (
          <button
            className="btn btn-default back-btn-container__backButton"
            onClick={onBackToRooms}
          >
            <i className="fas fa-angle-left mr-1"></i>
            <span>{text?.txtRoomsOverview || "Rooms Overview"}</span>
          </button>
        )}

        {specificSettings.showLogin && getIsApp() !== "true"
          ? !isLoggedIn && (
              <button
                className="btn btn-default back-btn-container__loginOutButton"
                onClick={onLogin}
              >
                <i className="fas fa-sign-in-alt mr-1"></i>
                <span>Login</span>
              </button>
            )
          : null}
        {isLoggedIn && logoutBtnJsx}
      </div>
    </div>
  );
};

export default Header;
