import React, { useEffect, useState } from "react";
import "./TrackedSessions.scss";
import routes from "./../../constants/routes.json";
import { getSessionsByIds, getTrackings } from "../../api/apiHelper";
import { getTrackingSessionIds, transferTrackedSessions } from "../../utils/Helper";
import TrackedItem from "./TrackedItem";
import { useNavigate } from "react-router-dom";
import { isValidURL } from "../../utils/Validator";
import Infobox from "../common/Infobox";

const TrackedSessions = ({
  loginData,
  setTrackedCount,
  eventId,
  isLoggedIn,
  hasTrackingsPending,
  ...rest
}) => {
  const [trackings, setTrackings] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      if (!loginData) {
        const trackedSessionIds = getTrackingSessionIds();
        if (trackedSessionIds?.length > 0) {
          const resp = await getSessionsByIds(eventId, trackedSessionIds);
          if (resp?.ok && resp?.data)
            setTrackings(
              resp?.data.map((c) => {
                return { id: c?.id, contentsession: c };
              })
            );
        }
        return;
      }

      const resp = await getTrackings(loginData); //todo
      if (resp?.ok) setTrackings(resp?.data);

      //delay execution
      setTimeout(() => {
        transferTrackedSessions(loginData?.user_id, setTrackedCount);
      }, 7 * 1000);
    })();
  }, [loginData]);

  const onItemClickHandler = (session) => {
    console.log(session);
    //
    //isValidURL
    if (
      session?.evaluation_type === "extern" &&
      isValidURL(session?.evaluation_link)
    ) {
      window.open(session.evaluation_link, "_blank", "noopener,noreferrer");
      return;
    }

    const pathname = `${routes.SESSIONS}/${session?.id}`;
    const search = window.location.search; // Preserving the current search string from the URL
    const state = { showEvalTab: true };
    navigate(pathname + search, { state });
  };

  return (
    <div className="qna-user-tracked-sessions">
      {isLoggedIn && !hasTrackingsPending && trackings?.length === 0 &&(
        <Infobox
          type="secondary"
          headerTitle={
            <>
              <i className="fas fa-info-circle mr-2"></i>
              {rest?.userTrackingModule?.txtNoTrackingsTitle}
            </>
          }
        >
          <div>{rest?.userTrackingModule?.txtNoTrackingsMsg}</div>
        </Infobox>
      )}
      {trackings?.map((c) => (
        <TrackedItem
          key={c.id}
          isLogged={!!loginData}
          onEvaluationClick={onItemClickHandler}
          {...rest}
          {...c}
        />
      ))}
    </div>
  );
};

export default TrackedSessions;
