import React, { useCallback, useEffect, useRef, useState } from "react";
import "./QrcodeScanner.scss";
import QrScanner from "qr-scanner";
import { useNavigate } from "react-router-dom";
import {DurationProgress, Modal, useVmWsSocket} from "react-vm-component-library";
import routes from "../../constants/routes.json";
import { saveUserInStorage, setTrackingSession } from "../../utils/Helper";
import { getEventId } from "../../utils/QueryString";
import { inflate64 } from "../../utils/PakoHelper";
import { createTrackings, vmLoginHelper } from "../../api/apiHelper";
import { formatString } from "../../utils/Tools";
import Infobox from "../common/Infobox";

const QrcodeScanner = (props) => {
  const {
    qrcodeScannerModule,
    setIsLoggedIn,
    isLoggedIn,
    logOut,
    loginData,
    setTrackedCount,
    text,
    loginBroadcasterConfig,
  } = props;
  const {
    txtScannSuccessHeading,
    txtScannSuccessMsg1,
    txtScannSuccessMsg2,
    txtScannSuccessMsg3,
    txtSignInAs,
    sessionRedirectSec
  } = qrcodeScannerModule || {};
  const [accessDenied, setAccessDenied] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [qrCodeScanned, setQrCodeScanned] = useState(false);
  const [loginMsg, setLoginMsg] = useState({ error: false, msg: "" });
  const [modalInfo,setModalinfo] = useState(null);
  const [broadcastChannel,setBroadcastChannel] = useState(null);
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const loginDataRef = useRef(null);
  const scannerRef = useRef(null);
  loginDataRef.current = loginData;
  const {
    subscribe,
    unsubscribe,
    sendToChannel,
    isConnectionOpen,
    channels
  } = useVmWsSocket(loginBroadcasterConfig?.socketUrl);
  console.log(channels);


  const qrScannerHandler = useCallback( async (result) => {
    // First check if the qr code is valid contain data
    if (result?.data && result?.data !== "") {
      // If data string contain qna.at or localhost:3000 for dev then navigate to session view page
      // futrue plan: if the user is not login save the session id into local storage
      // if the user is logged in send the seeion id to Vm api for tracking purpose
      console.log("decoded qr code:", result);
      setQrCodeScanned(true);
      if (
        result?.data.includes("qna.at") ||
        result?.data.includes("localhost:3000")
      ) {
       
        const url = new URL(result.data);
        const qsParams = new URLSearchParams(url.search);
        const qsToken = qsParams.get("token");
        if (!qsToken) {
          setLoginMsg({
            error: true,
            msg: qrcodeScannerModule.txtQrCodeError,
            header: qrcodeScannerModule.txtQrCodeErrorHeader,
          });
          return;
        }
        const decryptedQsToeken = JSON.parse(inflate64(qsToken));
        console.log(decryptedQsToeken);
        if (!decryptedQsToeken?.session_id) {
          setLoginMsg({
            error: true,
            msg: qrcodeScannerModule.txtQrCodeError,
            header: qrcodeScannerModule.txtQrCodeErrorHeader,
          });
          return;
        }

        if(decryptedQsToeken?.is_gate == "1" && loginBroadcasterConfig?.active){
          const channel_name =`login_broadcaster_session_${decryptedQsToeken?.session_id}`;
          setBroadcastChannel({url: channel_name, user:loginData?.user || {}})
        }

        //NOTE: all good
        let textArr = [];
        let isWarningInfo=undefined;
        textArr.push(
          <p key={0} className="bold">
            {txtScannSuccessMsg1}
          </p>
        );
        if (!loginDataRef.current) {
          textArr.push(<p key={1}>{txtScannSuccessMsg2}</p>);
          isWarningInfo=true;
          const count = setTrackingSession(decryptedQsToeken?.session_id);
          setTrackedCount(count);
        } else {
          const resp = await createTrackings(
            loginDataRef.current?.user_id,
            decryptedQsToeken?.session_id
          );
          console.log(resp);
        }
        textArr.push(<p key={2}>{formatString(txtScannSuccessMsg3, sessionRedirectSec)}</p>);
        setLoginMsg({
          error: false,
          msg: textArr,
          header: txtScannSuccessHeading,
          showProgress:true,
          isWarningInfo
        });

        scannerRef.current.stop();
        setTimeout(() => {
          navigate({
            pathname: `${routes.SESSIONS}/${decryptedQsToeken?.session_id}`,
            search: window.location.search,
          });
        }, sessionRedirectSec * 1000);
        
      } else {
        // If data is a string not an url send it to Vm api to check if the login is valid
        setIsLoading(true);
        scannerRef.current.stop();
        const postData = new FormData();
        postData.append("event_id", getEventId());
        postData.append("mode", "barcode_access");
        postData.append("include_grants", 0);
        postData.append("extra_check", result.data);
        const userData = await vmLoginHelper({
          isQrCodeLogin: true,
          postData: postData,
          saveInStorage:false//need modal confirm
        });
        setIsLoading(false);
        if (userData?.ok) {
          const respData = userData?.data;
          setModalinfo({
            data: respData,
            title: qrcodeScannerModule?.txtSignInTitle,
            children: (
              <div
                dangerouslySetInnerHTML={{
                  __html: formatString(
                    txtSignInAs,
                    respData?.user?.firstname,
                    respData?.user?.surname
                  ),
                }}
              ></div>
            ),
          });
        }

        if (!userData?.ok) {
          setLoginMsg({
            error: true,
            msg: qrcodeScannerModule.failedLoginTxt,
            header: qrcodeScannerModule.failedLoginHeaderTxt
          });
        }
      }
    }    
  },[])

  useEffect(() => {
    if (videoRef.current) {
      scannerRef.current = new QrScanner(
        videoRef.current,
        qrScannerHandler,
        qrcodeScannerModule?.scannerConfig
      );

      scannerRef.current
        .start()
        .then(() => {
          console.log("QR scanner started.");
        })
        .catch((e) => {
          setAccessDenied(true);
          console.error("Could not start QR scanner", e);
        });

      return () => {
        scannerRef.current.stop();
        scannerRef.current.destroy();
      };
    }
  }, [videoRef, qrScannerHandler]);

  useEffect(() => {
    if (!isConnectionOpen || !broadcastChannel) return;
    
    const {url, user} = broadcastChannel || {};
    subscribe(url, (data) => {
      console.log(data);
    });
    const timeoutId = setTimeout(()=>{
      sendToChannel(url, {
        ...(user || {}),
        timestamp: Date.now(),
      });
    }, 1 * 1000)
    return () =>  {
      unsubscribe(broadcastChannel);
      clearTimeout(timeoutId); // Cleanup the timeout
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [broadcastChannel, isConnectionOpen]);


  const onModalCancel =()=>{
    setModalinfo(null);
    scannerRef.current.start();
    setQrCodeScanned(false);
  };

  const onModalOk =()=>{
    console.log("dsd");
    setModalinfo(null);
    saveUserInStorage(modalInfo?.data);
    setIsLoggedIn(true);
    setLoginMsg({
      error: false,
      msg: (
        <>
          {qrcodeScannerModule.successLoginTxt}
          <div className="my-2">
            <button className="btn btn-default btn-primary-outline" onClick={()=>window.location.reload()}>
              <i className="fas fa-qrcode mr-2"></i>
              <span>{qrcodeScannerModule?.txtScanAnotherBtn}</span>
            </button>
          </div>
        </>
      ),
      header: qrcodeScannerModule.successLoginHeaderTxt,
    });
  };

  

  let signedInJsx = null;
  if (!qrCodeScanned && isLoggedIn) {    
    signedInJsx  =  (
      <div className="qna-qrcode-scanner__container">
        <Infobox
          type="info"
          headerTitle={
            <>
              <i className="fas fa-info-circle mr-2"></i>Info
            </>
          }
        >
          <p>
            {qrcodeScannerModule?.txtSignedIn}{" "}
            <b>
              {loginData?.user?.firstname} {loginData?.user?.surname}
            </b>
          </p>
          <p>
            <button className="btn btn-default btn-primary" onClick={logOut}>
              <i className="fas fa-sign-out-alt mr-2"></i>
              <span>{text?.txtBtnLogout}</span>
            </button>
          </p>
        </Infobox>
      </div>
    );
  }
   

  return (
    <div className="qna-qrcode-scanner__container">
      {modalInfo && (
        <Modal
          className="custom-css-class"
          onClose={onModalCancel}
          onYesClick={onModalOk}
          rootId="modal-root"
          title={modalInfo?.title}
          txtBtnYes={qrcodeScannerModule?.txtModalOk}
          txtBtnNo={qrcodeScannerModule?.txtModalCancel}
        >
          {modalInfo?.children}
        </Modal>
      )}
      {!qrCodeScanned && accessDenied && (
        <span className="qna-qrcode-scanner__container__info-text">
          {qrcodeScannerModule?.noCameraAccessTxt}
        </span>
      )}
      {!accessDenied && (
        <span className="qna-qrcode-scanner__container__info-text">
          {qrcodeScannerModule?.placeQrcodeCenterTxt}
        </span>
      )}
      <video
        className="qna-qrcode-scanner__container__scanner"
        ref={videoRef}
      />
      {qrCodeScanned && (
        <Infobox
          type={
            loginMsg.error
              ? "error"
              : loginMsg?.isWarningInfo
              ? "warning"
              : "success"
          }
          headerTitle={loginMsg.header}
          size={loginMsg?.isWarningInfo ? "small" : ""}
        >
          {isLoading ? (
            <div className="spinner-border"></div>
          ) : (
            <>
              <div>{loginMsg.msg}</div>
              {loginMsg?.showProgress && (
                <DurationProgress
                  durationSec={sessionRedirectSec - 1}
                  progressBarCssClasses="progress-bar-striped progress-bar-animation"
                />
              )}
            </>
          )}
        </Infobox>
      )}
      {signedInJsx}
    </div>
  );
};

export default QrcodeScanner;
