import React from "react";

const TabMsg = ({ type, children, hide }) => {
  const cssClasses = ["tab-info-msg"];
  if (type) cssClasses.push(`type-${type}`);
  if (hide) cssClasses.push("hide");
  return <div className={cssClasses.join(" ")}>{children}</div>;
};

export default TabMsg;
