import React, { useState } from "react";
import "./UserTracking.scss";
import TrackedSessions from "./TrackedSessions";
import LoginView from "./LoginView";
import Infobox from "../common/Infobox";
import { hasTrackingSession } from "../../utils/Helper";

function UserTracking(props) {
  const {
    isLoggedIn,
    setIsLoggedIn,
    userTrackingModule,
    qrcodeScannerModule,
    ...rest
  } = props;
  const [showLogin, setShowLogin] = useState(false);
  const hasTrackingsPending = hasTrackingSession();

  if (!isLoggedIn && (showLogin || !hasTrackingsPending)) {
    return (
      <LoginView
        userTrackingModule={userTrackingModule}
        setIsLoggedIn={setIsLoggedIn}
        qrcodeScannerModule={qrcodeScannerModule}
      />
    );
  }

  return (
    <div className="qna-user-tracking">
      {hasTrackingsPending && !isLoggedIn && (
        <Infobox
          type="secondary"
          headerTitle={
            <>
              <i className="fas fa-info-circle mr-2"></i>
              {userTrackingModule?.txtHasTrackingsTitle}
            </>
          }
        >
          <div>{userTrackingModule?.txtHasTrackingsMsg}</div>
          <div className="d-flex justify-content-end mt-2">
            <button
              className="btn btn-primary"
              onClick={() => setShowLogin(true)}
            >
              {userTrackingModule?.txtBtnSignIn}
            </button>
          </div>
        </Infobox>
      )}
      <TrackedSessions
        isLoggedIn={isLoggedIn}
        hasTrackingsPending={hasTrackingsPending}
        userTrackingModule={userTrackingModule}
        {...rest}
      />
    </div>
  );
}

export default UserTracking;
