import React from "react";
import "./Small.scss";

const Small = ({ type = "error", children }) => {
  const cssClasses = ["qna-v2-main-container__small form-text"];
  cssClasses.push(`type-${type}`)
  return <small className={cssClasses.join(" ")}>{children}</small>;
};

export default Small;
