export const appSettings = {
  test: "test string",
  timeZoneStr: "Europe/Berlin",
  appSpecificSettings: {
    showLogin: false,
    qnaForceLogin: false,
    showSessionTitle: true,
    showRoomOverview: true,
    showDurationProgress: true,
    qnaTabName: "QnA",
    pollingTabName: "Polling",
    evaluationTabName: "Evaluation",
    availableTabs: ["qna", "polling", "evaluation"],
    sessionbasedDynamicTabs: false,
    qnaTopImageHolder: null,
    roomOverviewApiStreaming: 1,
    defaultLoginEntrance: "",
    showAfterLogin: [],
  },
  text: {
    txtRoomsOverview: "Rooms Overview",
    txtBtnLogout:"Logout"
  },
  // Future scope, combine the config into module base 
  mainMenuModule:{
    active:false,
    items:{
      sessions:{
        active:true,
        titleTxt: "Sessions"
      },
      scanQrCode:{
        active:true,
        titleTxt:"Scan QR"
      },
      myTracking:{
        active:true,
        titleTxt: "My Tracking"
      }
    }
    
  },

  qnaModule: {
    userObject: {
      name: null,
      fullName: null,
      user_id: null,
      access_token: null,
    },
    moderatorMode: {
      active: false,
      token: "test",
      hideSubManageBtns: false,
    },
    isChairTable: false,
    poolIntervalSec: 60,
    showCollapsed: false,
    commentsPerPage: 7,
    showPerPageList: false,
    textareaMaxChars: 700,
    commentSourceType: "",
    commentPostIsHidden: false,
    commentLikes: false,
    commentHighlights: false,
    orderBy: "time",
    showFilters: false,
    showBookmarks: false,
    isUserNameWriteable: false,
    showSessionTitle: false,
    showAddresToListInForm: true,
    showAnsweredLabel:false,
    isAddresToListMadatory:false
  },
  qnaText: { showAfterLoginText: "Please login to see the qna, thank you." },

  votingModule:{
    votingConfig: {
      anonymVote: false,
      showIsCorrectAnswer: false,
      showTotalVotes: false,
      resultsMode: "percentage",
    },
    votingText: {
      btnSubmitVote: "Submit",
      votingCorrect: "Correct Answers",
      votingIncorrect: "Incorrect Answers",
      votingSuccess: "You voted.",
      txtPollingWillStartSoon: "Not started.",
      txtPollingWaitingToStart: "Currently, no voting is active.",
      txtVotingNotAllowedToVote:
        "You are not allowed to participate in voting, due to your account restrictions.",
      txtWaitingResults: "Not started.",
      anonymUserCannotVote: "Anonym users can't vote",
      txtTotalVotes: "Total votes",
      txtVote: "vote",
      txtVotes: "votes",
      txtEnterWords :"Enter word(s)"
    },
  },

  qrcodeScannerModule: {
    noCameraAccessTxt: "Please allowed the camera access in the browser settings for the qrcode scanner to work",
    placeQrcodeCenterTxt: "Please center the QR Code within the frame.",
    successLoginTxt: "You are successfully logged in.",
    successLoginHeaderTxt:"Thank you",
    failedLoginTxt:"Failed to login. Please check if the QR Code is valid.",
    failedLoginHeaderTxt:"Sorry",
    txtSignedIn:"You are signed as",
    txtQrCodeError:"The QR Code does not work with this app.",
    txtQrCodeErrorHeader:"Sorry",
    txtScannSuccessHeading:"Thank you",
    txtScannSuccessMsg1:"Your attendence has been tracked.",
    txtScannSuccessMsg2:" Please remember to sign in to transfer your trackings to the online CME system.",
    txtScannSuccessMsg3:"You will be redirected to the session page in {0} seconds.",
    txtSignInTitle:"Sign In ?",
    txtSignInAs:"Would you like to sign in as <b>{0} {1}</b>",
    txtScanAnotherBtn:"Scan another QR code",
    txtModalOk:"Ok",
    txtModalCancel:"Cancel",
    sessionRedirectSec:5,
    scannerConfig:{
      highlightScanRegion:true,
      highlightCodeOutline: true,
      maxScansPerSecond: 25
    }
  },

  userTrackingModule: {
    tipTxt:"Tip: You can scan your badge QR code to sign in.",
    //Tipp: Sie können Ihren Ausweis-QR-Code scannen, um sich anzumelden.
    scanBtnTxt:"Scan",
    //Scannen
    siginInInfoTxt:"Please sign in using the same credentials you used for your ticket.",
    //Bitte melden Sie sich mit denselben Anmeldeinformationen an, die Sie für Ihr Ticket verwendet haben.
    submitBtnTxt:"Login",
    //Anmelden
    txtHasTrackingsTitle:"Please sign in",
    txtHasTrackingsMsg:"You tracked your attendence, but it is not yet transfered to the CME System",
    txtBtnSignIn:"Sign in",
    txtNoTrackingsTitle:"No Tracking Available",
    txtNoTrackingsMsg:"You don't have tracking yet. Please scan session QR codes to enable tracking.",

    txtEmailLabel:"Email address",
    txtEmailPlaceholder:"Enter email",
    txtPasswordLabel:"Password",
    txtPasswordPlaceholder:"Enter password",

    txtValEmail:"Email is invalid!",
    txtValPassword:"Password is too short!",
    txtValGeneral:"Something went wrong!",
    txtModalTitleLoginSucess:"Login Successful",
    txtModalMsgLoginSucess:" Welcome! You have successfully logged in.",

    txtBtnEvaluate:"Evaluate",
    txtTransmitted:"Transmitted",
    txtNotTransmitted:"Not Transmitted",
  },

  evaluationModule: {
    noValueText: "There is no evaluation available for this session",
    noEvaluationForSession: "There will be no evaluation for this session",
    buttonText: "To Evaluation",
    userIdTmpl: "[user_id]",
    showAfterLoginText: "Please login to continue the evaluation, thank you.",
    useTranslationConfig:false,
    evaluationBeforeTimerText:"Evaluation will show {n} minutes before the session ends",
    evaluationAfterTimerText:"Evaluation will show {n} minutes after the session starts",
    evaluationText:{
      evaluationTitleText: "Evaluation",
      submitButtonText: "Submit",
      textBoxPlaceholderText:"Please comment here…",
      successSubmitContentText:
        "Thank you for your participation in the survey. If you would like to return please press the close button.",
      mandatoryQuestionsNotAnsweredText:
        "There are mandatory questions which are not answered. Please check, thank you.",
      paginationPreviousButtonText: "Back",
      paginationNextButtonText: "Next",
      paginationQuestionCompleteText: "Complete",
      userIsDoneText: "You have already done the evaluation, thank you",
      noEvaluationForSessionText: "There is no evaluation for this session.",
    }
  },

  loginSettings: {
    id: "manage-login",
    identityUrl: "https://id.m-anage.com/",
    oauthUrl: "https://m-anage.com/OAuth2/Authorize",
    client_id: "",
    apiUrl: "https://api.m-anage.com",
    instance: "qna",
    language: "en-GB",
    short: "vmx",
    context: "Event",
  },

  streamConfig: {
    active: false,
    type:"chimeAudioOnly",//[chimeAudioOnly, stream]
    roomsIds: [],
    txtOpenStream: "Open stream",
    txtCloseStream: "Close stream",
    url:"https://audio.m-events.com/live/?sid=",
    playerConfig: {
      miniPlayerActive: false,
      streamUrl: null,
      //streamUrl: "https://3d26876b73d7.us-west-2.playback.live-video.net/api/video/v1/us-west-2.913157848533.channel.xJ2tVekwmMGd.m3u8"
    },
  },

  countdownConfig:{
    showHoursBefore: 96,
    liveStrippedClass:"progress-bar-striped progress-bar-animated",
    liveShowCurrentTime: true,
    liveShowStartAndBeginTime: true,
    liveShowSeconds: true
    //...others from component library
  },

  expiredModeConfig: {
    title: "Information",
    content:
      "Session is expired. Would you like to stay in the room or leave ?",
    button1: "Stay",
    button2: "Leave",
  },
  roomsConfig:{
    "api_is_shown_app": 1,
  },
  loginBroadcasterConfig:{
    active:false,
    socketUrl: "wss://ws1.virtual-meeting.net/cable"
  },
  redirectConfig:{
    active:false,
    to:"/myTracking",
    from:"/roeko"
  },
  cssSetting:{
    active:false,
    url:"https://mevents-api-uploads.s3.amazonaws.com/api_uploads/mtv-master/dynamic-content/esmo/css/main_esmo.css"
  }
  //qnaforceLoginModeText:{}
  //anonymLoginModeText:{}
  //expiredModeText:{}
};

window.appSettings = appSettings;
