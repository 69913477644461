import React from "react";
import "./MainMenu.scss";
import routes from "../../constants/routes.json";
import { useNavigate } from "react-router-dom";
import { getCurrentQs } from "../../utils/Helper";
import MenuItem from "./MenuItem";

function MainMenu(props) {
  const { mainMenuModule, trackedCount } = props;
  const { sessions, scanQrCode, myTracking } = mainMenuModule?.items || {};
  const pathName = window.location.pathname;
  const navigate = useNavigate();

  const onMenuNavigate = (destination) => {
    navigate({
      pathname: destination,
      search: getCurrentQs(),
    });
  };

  return (
    <div className="qna-home-tabs-container">
      <nav className="qna-home-tabs-container__items">
        {sessions?.active && (
          <MenuItem
            isActive={pathName === routes.HOME}
            onClick={() => onMenuNavigate(routes.HOME)}
          >
            <i className="fas fa-list"></i>
            <span>{sessions?.titleTxt}</span>
          </MenuItem>
        )}

        {scanQrCode?.active && (
          <MenuItem
            isActive={pathName === routes.QRCODESCANNER}
            onClick={() => onMenuNavigate(routes.QRCODESCANNER)}
          >
            <i className="fas fa-qrcode"></i>
            <span>{scanQrCode?.titleTxt}</span>
          </MenuItem>
        )}

        {myTracking?.active && (
          <MenuItem
            isActive={pathName === routes.MYTRACKING}
            onClick={() => onMenuNavigate(routes.MYTRACKING)}
          >
            <i className="fas fa-user-circle"></i>
            <span>{myTracking?.titleTxt}</span>
            {trackedCount > 0 && <span className="qna-badge">{trackedCount}</span>}
          </MenuItem>
        )}
      </nav>
    </div>
  );
}

export default MainMenu;
