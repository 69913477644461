import { createContext, useState, useEffect } from "react";
import _ from "lodash";
import { fetchAppConfig, getVmBaseUrl } from "../api/apiHelper";
import { appSettings } from "../config/appSettings";

import { getEventId, getInfo } from "../utils/QueryString";
import { loadCss, setCssVariables } from "../utils/Tools";
import { isNullOrUndefined } from "../utils/Validator";
import { getTrackingSessionCount } from "../utils/Helper";

const QnAContext = createContext();

export function QnAProvider({ children }) {

  const [qnaAppSettings, setQnaAppSettings] = useState(appSettings);

  const [QnaConfig, setQnaConfig] = useState(appSettings.qnaModule);
  
  const [isConfigLoaded, setIsConfigLoaded] = useState(false);
  const [eventId, setEventId] = useState(null);
  const [trackedCount, setTrackedCount] = useState(0);

  const mergeSettings = async(pData) => {
    const value = JSON.parse(pData[0]?.translationkey?.translations[0].value);
    const test = _.merge({},_.cloneDeep(qnaAppSettings), value);
    //const test = _.merge(qnaAppSettings, value);
    if(value?.appSpecificSettings?.availableTabs?.length > 0){
      //NOTE: array by merge are always merged by length,
      // so extra assign needed. Avoid using array with length > 0 as default
      test.appSpecificSettings.availableTabs =value.appSpecificSettings.availableTabs;
    }
    setQnaAppSettings(test)
    setQnaConfig({ ...QnaConfig, ...value.qnaModule });


    if(value?.colors){
      setCssVariables(value.colors)
    }

    if (test?.cssSetting?.active) {
      await loadCss({
        path: test?.cssSetting?.url,
        domId: "esignage-dynamic-css",
      });
    }

    setIsConfigLoaded(true);
  };


  useEffect(() => {
    (async () => {
      console.log("Fetch the event id for context");
      let testeventId = getEventId();
      console.log("test",testeventId);

      if (isNullOrUndefined(testeventId)) {
        const hostName = window.location.hostname;
        const pathName = window.location.pathname;

        const translatableID =
          hostName === "localhost" || hostName === "staging.qna.at"
            ? `www.qna.at${pathName}` /*"www.qna.at/demo/" */
            : `${hostName + pathName}`;

        console.log(translatableID);

        const result = await fetchAppConfig({
          translatable_id: translatableID,
          translatable_type: "Url",
          use_cache:0
        });
        if (result?.ok) {
          testeventId = parseInt(result?.data[0].event_id);
          mergeSettings(result?.data);
        }
      }
      else{
        const result = await fetchAppConfig({ translatable_id: testeventId, use_cache:0 });
        if (result?.ok) {
          mergeSettings(result?.data);
        }
        console.log(result);
      }
      setEventId(parseInt(testeventId));
      setTrackedCount(getTrackingSessionCount())

    })();
  }, []);

  

  return !isConfigLoaded || !eventId ? (
    <QnAContext.Provider value="Loading123">
      <div>Loading...</div>
    </QnAContext.Provider>
  ) : (
    <QnAContext.Provider
      value={{
        qnaAppSettings,
        baseApi:getVmBaseUrl(),
        qnaText:qnaAppSettings?.qnaText,
        votingConfig:qnaAppSettings?.votingModule,
        QnaConfig,
        setQnaConfig,
        text:qnaAppSettings?.text,
        qnaForceLoginMode: qnaAppSettings?.qnaforceLoginModeText,
        anonymLoginMode: qnaAppSettings?.anonymLoginModeText,
        expiredMode: qnaAppSettings?.expiredModeText,
        specificSettings: qnaAppSettings?.appSpecificSettings,
        streamConfig: qnaAppSettings?.streamConfig,
        evaluationConfig: qnaAppSettings?.evaluationModule,
        countdownConfig: qnaAppSettings?.countdownConfig,
        timeZoneStr: qnaAppSettings?.timeZoneStr,
        loginSettings: qnaAppSettings?.loginSettings,
        eventId,
        roomsConfig: qnaAppSettings?.roomsConfig,
        qrcodeScannerModule: qnaAppSettings?.qrcodeScannerModule,
        mainMenuModule:qnaAppSettings?.mainMenuModule,
        userTrackingModule:qnaAppSettings?.userTrackingModule,
        loginBroadcasterConfig:qnaAppSettings?.loginBroadcasterConfig,
        setTrackedCount,
        trackedCount,
        
      }}
    >
      {children}
    </QnAContext.Provider>
  );
}

export default QnAContext;
