const MenuItem = ({ children, isActive, className, ...rest }) => {
  const cssClasses = ["qna-home-tab"];
  if (isActive) cssClasses.push("qna-home-tab--active");
  return (
    <div className={cssClasses.join(" ")} {...rest}>
      {children}
    </div>
  );
};

export default MenuItem;
