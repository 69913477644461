import _ from 'lodash';
import { inflate64 } from "../utils/PakoHelper";
import { isNullOrWhitespace } from "./Validator";
import { createTrackings, getVmBaseUrl } from "../api/apiHelper";
import * as Storage from "./LocalStorage";

const STORAGE_TRACKINGS = "qna.tracking";

const safeSessionId = (props) => {
  const currentStorage = JSON.parse(localStorage.getItem("eventSession"));
  localStorage.setItem(
    "eventSession",
    JSON.stringify({
      ...currentStorage,
      current_session_id: props.sessionId,
      current_event_id: props.eventId,
    })
  );
};

const checkTabs = (name, array, sessionAllowed, enabledSessionbasedCheck) => {
  if (array.indexOf(name) > -1) {
    if (!sessionAllowed && enabledSessionbasedCheck) return false;

    return true;
  } else {
    return false;
  }
};

const getUserData = async ({
  eventId,
  userToken,
  specificSettings,
  setQnaConfig,
}) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      event_id: eventId,
      jwt_token: userToken,
      include_user: 1,
      include_grants: 0,
    }),
  };

  await fetch(`https://${getVmBaseUrl()}/v0/logins/jwt.json`, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      if (specificSettings.showLogin && !specificSettings.qnaForceLogin) {
        setQnaConfig((prevState) => ({
          ...prevState,
          userObject: {
            ...prevState.userObject,
            name: data.user.firstname,
            fullName: data.user.firstname + " " + data.user.surname,
          },
        }));
      } else {
        setQnaConfig((prevState) => ({
          ...prevState,
          userObject: {
            ...prevState.userObject,
            name: data.user.firstname,
            fullName: data.user.firstname + " " + data.user.surname,
            access_token: data.access_token,
            user_id: data.user_id,
          },
        }));
      }
    });
};

const decryptParam = (title, url) => {
  const inflatedResult = inflate64(url);

  if (inflatedResult !== null) {
    const paramObject = JSON.parse(inflatedResult);
    const result = paramObject[title];

    if (result === null || result === undefined) {
      return null;
    } else {
      return result;
    }
  } else {
    return null;
  }
};

const paramsDecrypt = (string, encryptMode) => {
  if (!Number(string)) {
    const sid = inflate64(string);
    return parseInt(sid);
  } else {
    return parseInt(string);
  }
};

const getCurrentQs = () => {
  let qs = window.location.search;
  return isNullOrWhitespace(qs) ? null : qs;
};

const replaceBracketNtoMinute = (str, minute) => {
  if (str.includes("{n}")) return str.replace("{n}", minute);

  return str;
};

const decodeBase64 = (encodedString) => {
  return atob(encodedString);
};

const saveUserInStorage =(data)=>{
  const userData = data
    const tokenObj = {
      event_id: userData?.event_id,
      user_id: userData?.user_id,
      access_token: userData?.access_token,
      user: {
        ...userData?.user,
        firstname: userData?.user?.firstname || " ",
        surname: userData?.user?.surname || " ",
      },
      validUntil: new Date(userData.expires_at).valueOf(),
    };
    localStorage.setItem(
      `${userData?.event_id}.qna.user`,
      JSON.stringify(tokenObj)
    );
};

const sentMatomo =(...args)=>{
  try{
    if(args?.length > 0){
      // console.log([
      //   "trackEvent",
      //    ...args
      // ])
      window._paq.push([
        "trackEvent",
         ...args
      ]);
    }
    
  }catch(err){console.log(err)}
};

const setTrackingSession =(pSessionId)=>{
  try{
    const trackings = Storage.get(STORAGE_TRACKINGS) || {};
    trackings[pSessionId] = true;
    Storage.store(STORAGE_TRACKINGS,trackings)

    return Object.keys(trackings).length;
  }catch(err){console.log(err)} 
}

const getTrackingSessionCount =()=>{
  try{
    const trackings = Storage.get(STORAGE_TRACKINGS);
    if(_.isEmpty(trackings)) return 0;
    return Object.keys(trackings).length;
  }catch(err){console.log(err)} 
}

const getTrackingSessionIds =()=>{
  try{
    const trackings = Storage.get(STORAGE_TRACKINGS);
    if(_.isEmpty(trackings)) return [];
    return Object.keys(trackings);
  }catch(err){console.log(err)} 
  return [];
}

const hasTrackingSession =()=>{
  try{
    const trackings = Storage.get(STORAGE_TRACKINGS);
    if(_.isEmpty(trackings)) return false;
  }catch(err){console.log(err)} 
  return true;
}

const transferTrackedSessions = async (userId, pOnChangeCallback) => {
  try {
    const hasTrackings = hasTrackingSession();

    if (!hasTrackings) {
      console.log("no trackings found!")
      return
    };

    const trackings = Storage.get(STORAGE_TRACKINGS);
    const trackingsSessionIds = Object.keys(trackings);
    for (const sessionId of trackingsSessionIds) {
      const resp = await createTrackings(userId, sessionId);
      console.log(`Tracking transmitted for for sid:${sessionId}. `, resp);
      delete trackings[sessionId];
      Storage.store(STORAGE_TRACKINGS, trackings);
      if (typeof pOnChangeCallback === "function") pOnChangeCallback();
    }
  } catch (err) {
    console.log(err);
  }
  return true;
};



export {
  safeSessionId,
  checkTabs,
  getUserData,
  decryptParam,
  getCurrentQs,
  paramsDecrypt,
  replaceBracketNtoMinute,
  decodeBase64,
  saveUserInStorage,
  sentMatomo,
  setTrackingSession,
  hasTrackingSession,
  getTrackingSessionIds,
  getTrackingSessionCount,
  transferTrackedSessions
};
