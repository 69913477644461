import React from "react";

const Infobox = ({ type, headerTitle, children, size="normal" }) => {
  const cssClasses = ["qna-qrcode-scanner__msg-container"];
  cssClasses.push(`qna-qrcode-scanner__msg-container--${type}`);
  cssClasses.push(`qna-qrcode-scanner__msg-container--size-${size}`)
  return (
    <div className={cssClasses.join(" ")}>
      {headerTitle && <div className="msg-container-header">{headerTitle}</div>}
      <div className="msg-container-content">{children}</div>
    </div>
  );
};

export default Infobox;
