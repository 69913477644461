import _ from "lodash";
import { isNullOrUndefined } from "./Validator";
import { inflate64 } from "./PakoHelper";

const getQSValue = (pQsKey) => {
  if (isNullOrUndefined(pQsKey)) return null;

  const lQsKey = pQsKey.toLowerCase();
  const lUrlParams = getUrlParamsCaseIS(); //NOTE: make it case insensitive

  let isEncoded = false;
  let lQsValue = lUrlParams.get(lQsKey);

  //2nd fallback check token(encoded token with Pako)
  if (!lQsValue) {
    lQsValue = getFromToken(lQsKey);
    isEncoded = false;
  }

  //return (isEncoded && lQsValue) ? lQsValue : lQsValue;
  return lQsValue;
};

let finalTokenData = null;
let inflateStr = null;
const getFromToken = (pQsKey) => {
  try {
    if (!finalTokenData) {
      const qsToken = getToken();
      if (!qsToken) return null;
      //console.time("sd")
      let tokenObj = inflateStr ? inflateStr : inflate64(qsToken);
      if (!inflateStr) inflateStr = tokenObj; //remember token to speed up a littles

      console.log(tokenObj);
      if (_.isString(tokenObj)) {
        tokenObj = JSON.parse(tokenObj);
        //Translate new to old Pramerter Names
        /*if(tokenObj.event_id)
                    tokenObj.sid = tokenObj.VmSessionId;
                if(tokenObj.sid)
                    tokenObj.s_id = tokenObj.sid;*/

        Object.entries(tokenObj).forEach(([key, value]) => {
          tokenObj[key.toLowerCase()] = value;
        });
      }
      //console.timeEnd("sd")
      if (!_.isObject(tokenObj)) return null;

      finalTokenData = tokenObj;
    }

    let lValue = finalTokenData[pQsKey];
    return lValue ? lValue : null;
  } catch (err) {
    console.log(err);
  }

  return null;
};
window.getFromToken = getFromToken;

const getUrlParamsCaseIS = () => {
  const qsParams = new URLSearchParams(window.location.search);
  let lUrlParams = new URLSearchParams();
  for (const [name, value] of qsParams) {
    lUrlParams.append(name.toLowerCase(), value);
  }
  return lUrlParams;
};

const getUserId = () => {
  return getQSValue("user_id");
};

const getSystem = () => {
  if (!getQSValue("system")) return "api-lb.virtual-meeting.net";

  return getQSValue("system");
};

const getToken = () => {
  const urlParams = getUrlParamsCaseIS();
  const qsToken = urlParams.get("token");
  return qsToken ? qsToken : null;
};

const getEventId = () => {
  //const urlParams = getUrlParamsCaseIS();
  //const qsToken = urlParams.get("event_id");
  //return qsToken ? qsToken : null;
  return getQSValue("event_id");
};

const getRoomId = () => {
  //const urlParams = getUrlParamsCaseIS();
  //const qsToken = urlParams.get("room_id");
  //return qsToken ? qsToken : null;
  return getQSValue("room_id");
};

const getSessionId = () => {
  //const urlParams = getUrlParamsCaseIS();
  //const qsToken = urlParams.get("session_id");
  //return qsToken ? qsToken : null;
  return getQSValue("session_id");
};

const getIsDemo = () => {
  const urlParams = getUrlParamsCaseIS();
  const qsToken = urlParams.get("is_demo");
  const encryptedQsTokenEncrypt = urlParams.get("token");
  if (qsToken && qsToken === "1") return true;

  if (encryptedQsTokenEncrypt) {
    const decryptedQsToekn = JSON.parse(inflate64(encryptedQsTokenEncrypt));

    if (decryptedQsToekn?.is_demo === 1) return true;

    return false;
  }

  return false;
};

const getApiUrl = () => {
  return getQSValue("url");
};

const getInfo = () => {
  return getQSValue("info");
};

const getAccessToken = () => {
  return getQSValue("access_token");
};

const getIsApp = () => {
  return getQSValue("isApp");
};

const getReturnUrl = () => {
  return getQSValue("returnUrl");
};

export {
  getEventId,
  getSessionId,
  getIsDemo,
  getRoomId,
  getToken,
  getUserId,
  getApiUrl,
  getInfo,
  getAccessToken,
  getIsApp,
  getReturnUrl,
};
