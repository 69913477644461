import React from "react";

const ChimeAudioLink = ({ txtOpenStream, streamSetting, session }) => {
  let linkUrl = streamSetting?.url + session?.id;
  if (session?.room?.special_settings?.chimeAudio?.url)
    linkUrl = session?.room?.special_settings?.chimeAudio?.url;
  return (
    <div className="qna-audio-only-content">
      <a href={linkUrl} target="_blank" rel="noreferrer">
        {txtOpenStream || "Open audio version"}
        <i className="fas fa-headphones-alt"></i>
      </a>
    </div>
  );
};

export default ChimeAudioLink;
